@import 'video.js/dist/video-js.css';
@import './variables.css';
@import './base';
@import './ion-tab-bar/ion-tab-bar';
@import './ion-chip/ion-chip';
@import './ion-modal/ion-modal';
@import './ion-item/ion-item';
@import './ion-list/ion-list';
@import './icons/icons';
@import './pills/pills';
@import './ion-img-thumb/ion-img-thumb';
@import './buttons/buttons';
@import './colors/colors';
@import './forms/forms';
@import './layout/layout';
@import './cards/cards';
@import './widgets/widgets';
@import './ion-progress-bar/ion-progress-bar';
@import './menu/menu';
@import './ads/ads';
@import './user/user';
@import './player/player';
@import './article/article';
@import './switch';
@import './divider';
@import './ion-radio/ion-radio';
@import './ion-input/ion-input';
@import './ion-searchbar/ion-searchbar';
@import './banner/banner';


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants hover, focus {
        .typo-h1 {
            letter-spacing: 0.25px;
            @apply text-23 md:text-33 max-md:tracking-normal leading-normal;
        }
        .typo-h2 {
            letter-spacing: 0.15px;
            @apply text-19 md:text-23 md:tracking-normal leading-normal;
        }
        .typo-b1 {
            letter-spacing: 0.5px;
            @apply text-16 md:text-18 leading-normal;
        }
        .typo-b2 {
            letter-spacing: 0.25px;
            @apply text-14 md:text-16 leading-normal;
        }
        .typo-b3 {
            letter-spacing: 0.4px;
            @apply text-12 leading-normal;
        }

        .bold {
            @apply font-bold;
        }
        .semibold {
            @apply font-semibold;
        }
        .regular {
            @apply font-normal;
        }
        .medium {
            @apply font-medium;
        }
    }
}

body {
    @apply typo-b1 regular font-poppins;
}

ion-col {
    padding: 0;
}

@layer base {
    :root {
        --color-light-background: 27 27 27;
        --color-lighter-background: 52 52 52;
        --color-dark-background: 27 27 27;
        --color-secondary: 97 88 219;
        --color-primary: 240 139 31;
        --color-success: 103 206 103;
        --radius: 10px;
    }
}

.video-js {
    width: 100% !important;
}

.vjs-poster img {
    object-fit: cover !important;
    margin: auto !important;
    width: 100% !important;
}

.theo-player-wrapper,
.vjs-tech {
    height: 100%;
    width: 100%;
}

.full-width {
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
}

.truncated {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

// remove react-phone-input-2 base styles
.react-tel-input {
    .selected-flag {
        &:hover {
            background-color: initial !important;
        }
    }

    .country-list {
        .country:hover,
        .country.highlight {
            background-color: #141414 !important;
            color: white !important;
        }
    }
}
.cut-off-corner {
    background: #1c1c1c;
    position: relative;
    overflow: hidden;
}

.cut-off-corner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 80px solid transparent;
    border-right: 160px solid #1c1c1c;
    width: 0;
    z-index: 1;
    background: linear-gradient(135deg, #6158db 0%, rgba(76, 69, 172, 0.87) 100%);
}

.cut-off-corner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 120px solid transparent;
    border-right: 160px solid transparent;
    border-bottom: 120px solid transparent;
    border-left: 120px solid #6158db;
    width: 0;
    z-index: 2;
    transform: translateY(-135px) translateX(-160px) rotate(-135deg);
}

.picture-report-cut-corner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-bottom: 65px solid transparent;
    border-left: 65px solid #f08b1f;
    width: 0;
    overflow: hidden;
    z-index: 0;
}

.picture-report-cut-corner-mobile:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-bottom: 35px solid transparent;
    border-left: 35px solid #f08b1f;
    width: 0;
}

@media (max-width: 640px) {
    .cut-off-corner:before {
        border-top-width: 25px;
        border-right-width: 60px;
    }

    .cut-off-corner:after {
        border-top-width: 60px;
        border-right-width: 80px;
        border-bottom-width: 60px;
        border-left-width: 60px;
        transform: translateY(-67.5px) translateX(-75px) rotate(-135deg);
    }
}

ion-icon {
    pointer-events: none;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.video-js:not(.theoplayer-skin) .vjs-big-play-button {
    left: 50%;
    top: 50%;
}

.article-description {
    ul {
        list-style: disc;
    }
    a {
        position: relative;
        text-decoration: underline;
        transition: all 0.1s ease-in-out;
        &:hover {
            color: var(--eldo-primary);
        }
    }
}
.theoplayer-skin {
    .theoplayer-poster {
        background-size: cover !important;
    }
}

ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;
    --max-width: 600px;
    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
}
ion-popover {
    --width: fit-content !important;
    --height: 'auto';
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.remove-arrow {
    -moz-appearance: textfield;
}

.article-description {
    white-space: pre-wrap;
}

.letz-ai-loading-indicator {
    width: 40px;
    height: 40px;
    --c: no-repeat linear-gradient(orange 0 0);
    background: var(--c), var(--c), var(--c), var(--c);
    background-size: 21px 21px;
    animation: l5 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}
@keyframes l5 {
    0% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
    33% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        width: 60px;
        height: 60px;
    }
    66% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0;
        width: 60px;
        height: 60px;
    }
    100% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    }
}
