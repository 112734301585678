/** sets a style rule for the IonItemDivider **/
/* https://ionicframework.com/docs/theming/colors#adding-colors */

:root {
    --ion-color-divider: transparent; // color refers to the background
    --ion-color-divider-contrast: var(--ion-color-primary-contrast); // refers to the text color
    --ion-color-divider-contrast-rgb: var(--ion-color-primary-contrast-rgb); // refers to the text color
}

.ion-color-divider {
    --ion-color-base: var(--ion-color-divider);
    --ion-color-contrast: var(--ion-color-divider-contrast);
    --ion-color-contrast-rgb: var(--ion-color-divider-contrast-rgb);
    font-weight: 600;
}

ion-item {
    --background: transparent;
    --detail-icon-opacity: 1;
    --border-width: 0px;
    --border-color: transparent;
}

ion-item.game-input {
    --background: #343434;
    --detail-icon-opacity: 1;
    --border-width: 0px;
    --border-color: transparent;
    width: 100%;
    --border-radius: 8px 8px 0px 0px;
    --padding-start: 15px;
    height: 54px;
}

ion-item.item-has-focus > ion-label,
ion-item.item-has-value > ion-label {
    color: rgba(255, 255, 255, 0.38) !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    letter-spacing: 0.4px !important;
    position: absolute;
    top: 4px;
}

ion-label.game-input {
    margin: 0;
    position: absolute;
    margin-top: -10px !important;
}

@media only screen and (max-width: 768px) {
    ion-label.game-input {
        margin-top: 0px!important;
    }
}

.game-input.label-floating.sc-ion-label-md-h {
    position: absolute;
    top: -7px;
}

ion-label {
    font-size: inherit;
}

ion-list.list-md,
ion-list.list-ios {
    background-color: transparent;
}

ion-note {
    --color: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0;
    align-self: auto;
}

ion-item-divider {
    @extend .bottom-line-90p;
}
