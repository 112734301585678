/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables custom for ELDO **/
:root {
    /** primary **/
    --ion-color-primary: var(--eldo-background-color);
    --ion-color-primary-rgb: 20, 20, 20;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #121212;
    --ion-color-primary-tint: #2c2c2c;

    /** secondary **/
    --ion-color-secondary: #f08b1f;
    --ion-color-secondary-rgb: 240, 139, 31;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #d37a1b;
    --ion-color-secondary-tint: #f29735;

    /** tertiary **/
    --ion-color-tertiary: #6158d8;
    --ion-color-tertiary-rgb: 97, 88, 216;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #554dbe;
    --ion-color-tertiary-tint: #7169dc;

    /** success **/
    --ion-color-success: #67ce67;
    --ion-color-success-rgb: 103, 206, 103;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #5bb55b;
    --ion-color-success-tint: #76d376;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #f0381f;
    --ion-color-danger-rgb: 240, 56, 31;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d3311b;
    --ion-color-danger-tint: #f24c35;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

/** ELDO Global Theming **/
:root {
    --ion-background-color: var(--eldo-background-color);
    --ion-background-color-rgb: 20, 20, 20;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #202020;
    --ion-color-step-100: #2c2c2c;
    --ion-color-step-150: #373737;
    --ion-color-step-200: #434343;
    --ion-color-step-250: #4f4f4f;
    --ion-color-step-300: #5b5b5b;
    --ion-color-step-350: #666666;
    --ion-color-step-400: #727272;
    --ion-color-step-450: #7e7e7e;
    --ion-color-step-500: #8a8a8a;
    --ion-color-step-550: #959595;
    --ion-color-step-600: #a1a1a1;
    --ion-color-step-650: #adadad;
    --ion-color-step-700: #b9b9b9;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dcdcdc;
    --ion-color-step-900: #e8e8e8;
    --ion-color-step-950: #f3f3f3;
    --ion-toolbar-color: white;
}
