@mixin breakpoint-min($bp) {
    @media (min-width: #{$bp}px) {
        @content;
    }
}

@mixin breakpoint-max($bp) {
    @media (max-width: #{$bp - 1}px) {
        @content;
    }
}

@mixin breakpoint-min-max($bp1, $bp2) {
    @media (min-width: #{$bp1}px) and (max-width:#{$bp2 - 1}px) {
        @content;
    }
}

.globalFontSize {
    font-size: 1rem;
    @include breakpoint-min($xl) {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
    }
}
