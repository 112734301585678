@import 'form-variables';

.input-password {
    position: relative;
}

.input-password > span {
}

input[type='date'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    text-align: left;
    height: 54px;
    line-height: 54px;
    display: flex;
    flex: 1 0 0;
}
