%roundBtn {
    --border-radius: 50px;
}

%sizeSmall {
    height: 40px;
    width: 40px;
}

%sizeNormal {
    height: 45px;
    width: 45px;
}

%sizeLarge {
    height: 50px;
    width: 50px;
}

.eldo-action-btn {
    @extend %roundBtn;
    @extend %sizeNormal;
    --background: var(--ion-color-field);
    --color: #fff;
    --box-shadow: 0;

    &.eldo-player-btn {
        --background: #fff;

        .custom-svg {
            fill: #1b1b1b;
        }
    }

    &.eldo-player-btn.rounded-btn--large {
        @extend %sizeLarge;
    }

    &.eldo-player-btn.rounded-btn--small {
        @extend %sizeSmall;
    }

    &--text {
        --background: transparent;
        --color: #fff;
        --box-shadow: 0;
        width: auto;

        .custom-svg {
            fill: #fff;
            width: 25px;
            margin-left: 10px;
        }
    }
}

// todo make a generic round item for btn and icon wrapper
.rounded-btn {
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rounded-btn--large {
        width: 50px;
        height: 50px;
    }

    &.player-btn {
        ion-icon {
            fill: var(--ion-color-primary);
        }
    }

    .card-component-generic & {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;

        &.rounded-btn--large {
            margin-left: -25px;
        }

        &.card-player-btn--right {
            top: auto;
            right: 10px;
            left: auto;
            bottom: -25px;
        }
    }
}

// ion-button white
:root {
    --ion-color-button-alt: #ffffff;
    --ion-color-button-alt-rgb: 255, 255, 255;
    --ion-color-button-alt-contrast: #141414;
    --ion-color-button-alt-contrast-rgb: rgba(20, 20, 20, 1);
    --ion-color-button-alt-shade: #e0e0e0;
    --ion-color-button-alt-tint: #ffffff;
}

.ion-color-button-alt {
    --ion-color-base: var(--ion-color-button-alt);
    --ion-color-base-rgb: var(--ion-color-button-alt-rgb);
    --ion-color-contrast: var(--ion-color-button-alt-contrast);
    --ion-color-contrast-rgb: var(--ion-color-button-alt-contrast-rgb);
    --ion-color-shade: var(--ion-color-button-alt-shade);
    --ion-color-tint: var(--ion-color-button-alt-tint);

    // added styles
    --border-radius: 50px;
    min-height: 50px;
}

.eldo-button {
    height: 50px;
    border-radius: 50px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    color: white;
    line-height: 50px;
    border: 1px solid white;
    background: var(--eldo-background-color);
    transition: 0.3s;
}

.eldo-button:hover {
    background: white;
    color: #333333;
}

.eldo-button:disabled {
    border: 0;
    background-color: var(--eldo-disabled);
    color: var(--eldo-background-alt-color);
}

.eldo-title-link-btn {
    margin-inline-end: 0 !important;
    --padding-end: 0 !important;
}
