// basic tag
:root {
    --ion-color-tag: rgba(97, 88, 219, 0.9);
    --ion-color-tag-rgb: 97, 88, 219;
    --ion-color-tag-contrast: #ffffff;
    --ion-color-tag-contrast-rgb: 255, 255, 255;
    --ion-color-tag-shade: #554dc1;
    --ion-color-tag-tint: #7169df;
}

.ion-color-tag {
    --ion-color-base: var(--ion-color-tag);
    --ion-color-base-rgb: var(--ion-color-tag-rgb);
    --ion-color-contrast: var(--ion-color-tag-contrast);
    --ion-color-contrast-rgb: var(--ion-color-tag-contrast-rgb);
    --ion-color-shade: var(--ion-color-tag-shade);
    --ion-color-tint: var(--ion-color-tag-tint);
}

// light tag
:root {
    --ion-color-light-tag: rgb(255, 255, 255, 0.75);
    --ion-color-light-tag-rgb: 255, 255, 255;
    --ion-color-light-tag-contrast: #1b1b1b;
    --ion-color-light-tag-contrast-rgb: 0, 0, 0;
    --ion-color-light-tag-shade: #e0e0e0;
    --ion-color-light-tag-tint: #ffffff;
}

.ion-color-light-tag {
    --ion-color-base: var(--ion-color-light-tag);
    --ion-color-base-rgb: var(--ion-color-light-tag-rgb);
    --ion-color-contrast: var(--ion-color-light-tag-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-tag-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-tag-shade);
    --ion-color-tint: var(--ion-color-light-tag-tint);
}

//

ion-item.eldo-pill {
    --min-height: 25px;
    height: 30px;
    display: flex;
    flex-direction: column;
}

.eldo-pill {
    --background: rgba(var(--ion-color-tertiary-rgb), 0.9);
    --color: var(--ion-color-tertiary-contrast);
    border-radius: 10px;
    font-size: 0.8rem;
    --inner-border-width: 0; // removes a thin stripe appearing on the pills

    &--rounded {
        border-radius: 50px;
    }

    &.is-live {
        --background: rgba(255, 255, 255, 0.9);
        --color: var(--ion-color-primary);
        align-items: center;
        height: 25px;

        :before {
            content: '';
            width: 6px;
            height: 6px;
            display: inline-block;
            border-radius: 50%;
            background-color: var(--ion-color-danger);
            margin-right: 4px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    & + & {
        margin-left: 10px;
    }
}
