// ads blocks

.eldo-ad {
    background-color: var(--ion-color-step-200);
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.is-billboard {
    height: 120px;
    margin: 0;
}

.is-sponsor-ad {
    height: 120px;
    border-radius: 10px;
    margin: 16px 0;
}

.is-sponsor-article-ad {
    height: 170px;
    border-radius: 10px;
    margin: 16px 0;
}

// takes full width of the parent, but flexible height
.is-native-ad {
    min-height: 250px;
    @include breakpoint-min($lg) {
        height: 100%;
    }

    &--small {
        min-height: 120px;
        border-radius: 10px;
    }
}

.is-imu-ad,
.is-cpc-ad {
    height: 250px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.is-half-page-ad {
    height: 50vh;
}

.is-in-page-ad,
.is-classified-box-ad {
    height: 197px;
    // height: 200px;
}
