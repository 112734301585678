/** Grey cards **/
:root {
    --ion-color-cards: #1b1b1b;
    --ion-color-cards-rgb: 27, 27, 27;
    --ion-color-cards-contrast: #ffffff;
    --ion-color-cards-contrast-rgb: 255, 255, 255;
    --ion-color-cards-shade: #181818;
    --ion-color-cards-tint: #323232;
}

.ion-color-cards {
    --ion-color-base: var(--ion-color-cards);
    --ion-color-base-rgb: var(--ion-color-cards-rgb);
    --ion-color-contrast: var(--ion-color-cards-contrast);
    --ion-color-contrast-rgb: var(--ion-color-cards-contrast-rgb);
    --ion-color-shade: var(--ion-color-cards-shade);
    --ion-color-tint: var(--ion-color-cards-tint);
}

/** Grey launcher **/
:root {
    --ion-color-launcher: #202020;
    --ion-color-launcher-rgb: 32, 32, 32;
    --ion-color-launcher-contrast: #ffffff;
    --ion-color-launcher-contrast-rgb: 255, 255, 255;
    --ion-color-launcher-shade: #1c1c1c;
    --ion-color-launcher-tint: #363636;
}

.ion-color-launcher {
    --ion-color-base: var(--ion-color-launcher);
    --ion-color-base-rgb: var(--ion-color-launcher-rgb);
    --ion-color-contrast: var(--ion-color-launcher-contrast);
    --ion-color-contrast-rgb: var(--ion-color-launcher-contrast-rgb);
    --ion-color-shade: var(--ion-color-launcher-shade);
    --ion-color-tint: var(--ion-color-launcher-tint);
}
