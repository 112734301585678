ion-tab-bar {
    //   --background: linear-gradient(to bottom, #1b1b1b, rgba(20, 20, 20, 0));
    //   background: rgb(20, 20, 20);

    contain: style;
    height: 60px;
    position: relative;
    --background: #1b1b1b;
    --border: 0;
    // background-image: linear-gradient(
    //   to bottom,
    //   rgba(20, 20, 20, 0) 0,
    //   rgba(20, 20, 20, 0.8) 20%,
    //   rgba(20, 20, 20, 1) 100%
    // );
    // linear-gradient(
    //   to top,
    //   rgba(20, 20, 20, 0) 0%,
    //   rgba(20, 20, 20, 0.8) 20%,
    //   rgba(20, 20, 20, 1) 100%
    // );
    background-repeat: no-repeat;
    background-position: left bottom;

    &::before {
        content: '';
        // background: linear-gradient(to top, #1b1b1b, transparent);
        // background: -moz-linear-gradient(
        //   top,
        //   rgba(20, 20, 20, 0) 0%,
        //   rgba(20, 20, 20, 0.8) 80%,
        //   rgba(20, 20, 20, 1) 100%
        // ); /* FF3.6-15 */
        // background: -webkit-linear-gradient(
        //   top,
        //   rgba(20, 20, 20, 0) 0%,
        //   rgba(20, 20, 20, 0.8) 80%,
        //   rgba(20, 20, 20, 1) 100%
        // ); /* Chrome10-25,Safari5.1-6 */
        background-image: linear-gradient(
            to bottom,
            rgba(27, 27, 27, 0) 0%,
            // rgba(27, 27, 27, 0.8) 20%,
            rgba(27, 27, 27, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        width: 100%;
        height: 20px;
        top: -20px;
        margin: 0;
        position: absolute;
        z-index: 0;

        background-repeat: no-repeat;
        background-position: left bottom;
        background-blend-mode: difference;
    }
}

ion-tab-button {
    --background: transparent;
    --color: #fff;
    --color-selected: #fff;

    &.tab-selected {
        font-weight: bold;
    }
}
