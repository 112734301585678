@import './layout-variables';

ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 25px;

    @include breakpoint-min($lg) {
        --padding-start: 0;
        --padding-end: 0;
    }
}

.eldo-bottom-tab-bar {
    @include breakpoint-min($lg) {
        display: none;
    }
}

.eldo-bottom-tab-bar__btn-normal {
    position: relative;

    &.tab-selected {
        &::after {
            content: '';
            display: block;
            background-color: var(--ion-color-secondary);
            border-radius: 10px;
            width: 7px;
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -3px;
        }
    }
}

.mobile-header {
    @include breakpoint-min($lg) {
        display: none;
    }
}

// ios specific styles
.header-ios ion-toolbar:last-of-type {
    --border-width: 0;
}

@include breakpoint-min($lg) {
    .page-component__content {
        > .grid-fixed {
            $mr: 20px;
            // background: aliceblue;
            background: var(--ion-background-color);
            padding-left: $mr;
            padding-right: $mr;
            position: relative;
        }

        .eldo-bg-image {
            position: absolute;
            left: -140px;
            top: 55%;
            background-image: #{$moon-bg};
            background-size: 460px 495px;
            background-repeat: no-repeat;
            z-index: -1;
            width: 100%;
            height: 495px;
            filter: blur(71px);
            -webkit-filter: blur(80px);
            transform: rotate(11.58deg);
            opacity: 0.4;

            .tall-page & {
                top: 100%;
            }
        }
    }
}
