.player__presenter {
    ion-thumbnail {
        --border-radius: 5px;
    }
}

#player-expanded {
    z-index: 10000;

    ion-range::part(knob) {
        display: none;
    }

    ion-range {
        margin-bottom: 10px;
        padding: 0;
        --bar-background-active: #f08b1f;
        --bar-height: 4px;
        --bar-border-radius: 8px;
        --knob-background: transparent;
        --knob-size: 40px;
        --pin-background: #ffafcc;
        --pin-color: #fff;
    }
}
