:root {
    --eldo-background-color: #141414;
    --eldo-background-alt-color: #343434;
    --eldo-border-color: white;
    --eldo-secondary: #f08b1f;
    --eldo-primary: #f08b1f;
    --eldo-disabled: #8a8a8a;
    --eldo-warning: red;
    --eldo-success: #67ce67;
}
