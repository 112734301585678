@import './icons-variables';

%secondaryBg {
    fill: var(--ion-color-secondary);
}

%whiteBg {
    fill: #fff;
}

.eldo-icon {
    width: 100%;
    height: 100%;
    max-height: 35px;
    max-width: 35px;
    display: block;
}

.custom-svg {
    &--moon_Eldo {
        @extend %secondaryBg;
        height: 14px;
        width: 13px;

        .title-default & {
            margin-right: 10px;
        }
    }

    &--heart {
        width: 24px;
        @extend %whiteBg;
    }

    &--heart-active {
        width: 24px;
        @extend %secondaryBg;
    }

    &--add {
        width: 24px;
        // @extend %whiteBg;
    }

    &--cross,
    &--menu {
        width: 20px;
    }

    &--menu {
        width: 21px;
    }

    &--back {
        @extend %whiteBg;
    }

    &--search {
        @extend %whiteBg;
    }

    &--star {
        @extend %whiteBg;
    }

    &--star-active {
        @extend %secondaryBg;
    }

    &--logo-eldo {
        width: 155px;
        height: 135px;
        max-width: none;
        max-height: none;
        cursor: pointer;
    }
}

.custom-icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.has-circle {
        border-radius: 50px;
        width: 45px;
        height: 45px;
        background-color: var(--ion-color-field);

        &.alt-bg {
            background-color: var(--ion-color-secondary);
        }
    }

    &.logo-eldo-icon {
        display: flex;

        .custom-svg--logo-eldo {
            max-width: none;
            max-height: none;
            width: 89px;
            height: 69px;
        }
    }
}

// helper mixin for the display of icons on the tabsbar
@mixin tabIcon($name, $selected-name) {
    background-image: url(#{$name});
    .tab-selected & {
        background-image: url(#{$selected-name});
    }
}

.eldo-icon {
    &--css-type {
        height: 35px;
        width: 35px;
        background-repeat: no-repeat;
    }

    &-home {
        @include tabIcon($home-tab, $home-tab-active);
    }

    &-radio {
        @include tabIcon($radio-tab, $radio-tab-active);
    }

    &-news {
        background-image: url(#{$news-tab});
        position: relative;
        .tab-selected & {
            background-image: url(#{$news-tab-active});
            top: -5px;
        }
    }

    &-menu {
        @include tabIcon($menu-tab, $menu-tab-active);
    }

    &-event {
        @include tabIcon($event-tab, $event-tab-active);
        width: 50px;
        height: 50px;
        background-size: contain;
        max-width: none;
        max-height: none;
        position: relative;
        top: -6px;
    }
}
