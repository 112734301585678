@import './cards-variables';
@import './flat-thumbed-card';

ion-card {
    --background: var(--ion-color-cards);
    --color: var(--ion-color-cards-contrast);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 30px;
    padding: 10px;

    & + & {
        margin-top: 30px;
    }

    &.category__card,
    &.article__card {
        margin-bottom: 0;
    }
}

ion-card-title,
ion-card-subtitle {
    color: inherit;
}

ion-card-title {
    font-size: 1rem;
    font-weight: 600;
}

ion-card-subtitle {
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: none;
}

ion-card-header {
    padding-bottom: 15px;
    padding-top: 15px;
}

.card-component {
    padding: 10px;
    text-align: left;

    // includes cards that are not of type card-component-radio
    // for example cards from Focus Carousel
    &.card-component-generic {
        height: 460px;
        width: 289px;

        @include breakpoint-min($lg) {
            width: 255px;
        }

        ion-thumbnail {
            position: relative;
            height: 300px;
            width: 269px;

            @include breakpoint-min($lg) {
                width: 235px;
            }
        }

        ion-card-header {
            margin-top: -30px;
        }

        ion-card-title {
            min-height: 50px;
        }

        // mostly used for news
        &.card-component--compact {
            width: auto;
            flex-direction: column;
            height: 170px;
            margin: 0 0;

            ion-thumbnail {
                height: 100%;
                flex: 1 0 35%;
            }

            ion-card-header {
                margin-top: 0;
            }

            .card-component-generic__inner {
                height: 170px;
                @extend .flex-direction-row;
            }
        }

        // mostly used for pictures report
        &.card-component--narrow {
            height: 500px;
            width: 289px;
        }

        // mostly used for best moments
        &.card-component--medium {
            height: 380px;
            width: 260px;

            ion-card-header {
                padding-left: 10px;
                padding-right: 0;
            }

            ion-thumbnail {
                max-width: 100%;
                height: 220px;
                width: 100%;
            }

            &-gaming {
                height: 434px;
                width: 250px;
                --background: linear-gradient(180deg, rgba(97, 88, 219, 0.4) 0%, rgba(97, 88, 219, 0) 100%);

                ion-card-header {
                    margin-top: -40px;
                }

                .gaming-icon-wrapper {
                    background: #6158db;
                    width: 55px;
                    height: 50px;
                    border-radius: 5px;
                }
            }
        }
        &.card-component--shows {
            height: 550px;
            width: 325px;
            margin: 25px 5px 25px 5px;

            ion-thumbnail {
                max-width: 100%;
                height: 220px;
                width: 100%;
            }

            ion-card-header {
                padding-left: 0;
            }
        }
    }

    &.card-component-wide {
        .card-component-wide__container {
            position: relative;
            height: auto;
            border-radius: 10px;

            ion-thumbnail {
                height: 100%;
                width: 100%;
            }
        }

        .card-component-wide__image {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            object-fit: cover;
        }

        ion-card-header {
            padding-left: 0;
        }

        &--small {
            .card-component-wide__container {
                padding: 33% 0 0 0;
                padding: 105px 0 0 0;
            }
        }
    }

    ion-img {
        border-radius: 10px;
    }

    &__title {
        margin-bottom: 6px;
    }

    &__subtitle {
        p {
            margin: 0;
        }
    }
}
// end of .card-component

.card-component-generic__inner {
    @extend .display-flex;
    @extend .flex-direction-column;
}

ion-img + ion-card-header {
    padding: 20px 0 10px 0;
}

.card-component--compact {
    display: flex;
    flex-direction: row;
    padding: 0;

    ion-thumbnail {
        height: 100%;
        flex: 1 0 35%;
    }

    ion-img {
        border-radius: 10px 0 0 10px;
    }

    ion-card-subtitle {
        p {
            margin: 0;
        }
    }
}

.card-component__header-tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.card-component__header-tags__left {
    display: flex;
}

.card-icons-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.card-icons-row--floating {
        position: absolute;
        top: 20px;
        left: 20px;
    }
}

.card-icons-row__right {
    .custom-svg-wrapper {
        margin: 0 10px;
    }
}

.pattern-waves-wrapper {
    position: absolute;
    bottom: -20px;
    left: -10px;
}

.card-component__subtitle {
    &--inline-with-space {
        display: flex;
        justify-content: space-between;
    }
}

.eldo-topics-col {
    ion-card {
        margin-bottom: 0;
    }
}

// cards helpers/wrappers/overrides
@include breakpoint-min($lg) {
    .col-media {
        display: flex;
        flex-direction: column;

        .card-component-radio {
            flex: 1 0 auto;
        }
    }
}

.bg-gradient-purple {
    background: linear-gradient(180deg, rgba(97, 88, 219, 0.4) 0%, rgba(97, 88, 219, 0) 100%);
}
