/*font-face: Poppins*/

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('./font-face/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('./font-face/Poppins-Bold.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('./font-face/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('./font-face/Poppins-Medium.ttf');
}

:root {
    --ion-font-family: 'Poppins';
}

html {
    //font-size: 0.625rem;
}

body {
    //font-size: 1.6rem;
}

.bottom-line-90p {
    border-bottom: 0;
    padding-bottom: 15px;
    position: relative;
    &::after {
        content: '';
        width: 90%;
        height: 1px;
        background-color: #555555;
        display: block;
        position: absolute;
        bottom: 0;
        left: 1.6rem;
    }
}
